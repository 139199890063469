export default {
  // module: item group 1
  GROUP1_JENIS: {
    NON_UNIT: 'NON UNIT',
    UNIT: 'UNIT'
  },

  // module: progress unit
  STATUS_TEKNIK: {
    DITERIMA: 'DITERIMA',
    DITOLAK: 'DITOLAK'
  },

  // module: spk
  JENIS_SPK: {
    NON_UNIT: 'NON UNIT',
    UNIT: 'UNIT',
    PERENCANAAN: 'PERENCANAAN',
    MARKETING: 'MARKETING',
    LEGAL: 'LEGAL',
    ESTATE: 'ESTATE',
    HRGA: 'HRGA',
    OTHER: 'OTHER'
  },
  JENIS_KONTRAK_SPK: {
    JASA: 'JASA'
  },
  STATUS_APPROVAL: {
    PROSES: 'PROSES',
    DITERIMA: 'DITERIMA',
    DIREVISI: 'DIREVISI',
    DITOLAK: 'DITOLAK'
  },

  JENIS_PO: {
    BARANG: 'BARANG',
    JASA: 'JASA'
  },

  PPN_JENIS: {
    NO_PPN: 'TANPA PPN',
    WITH_PPN: 'DENGAN PPN'
  },

  // module: marketing -> kontrak
  TIPE_ANGSURAN: {
    BOOKING_FEE: 'BOOKING FEE',
    UANG_MUKA: 'UANG MUKA',
    ANGSURAN: 'ANGSURAN'
  }
}
